section#equipment {
  background-color: transparent;
  //background-image: url('Images/bgs/equipmentNoAlpha@2x.jpg');
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: center center;
  position: relative;
  height: 560px;
  padding-bottom: 75px;
  padding-top: 80px;
  overflow: hidden;
  margin-top: -100px;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 100%;
    width: 200%;
    height: 200%;
    transform: translate(-25%, 0%);
    background-color: hsl(216, 21%, 16%);
    top: 0;
    background-image: url('Images/bgs/equipmentNoAlpha@2x.jpg');
    background-repeat: no-repeat;
    background-size: 50% auto; // Todo: this and the value below will need adjusting for breakpoints.
    background-position: center top;
    z-index: -1;

    @media (min-width: 2300px) {
      background-position: center 150% !important;
    }
    @media (min-width: 1900px) {
      background-position: center -25%;
    }
    @media (min-width: 1700px) {
      background-position: center -50%;
    }
    @media (max-width: 1700px) {
      background-position: center -10%;
    }
    @media (max-width: 1250px) {
      background-position: center top;
    }

    @include media-breakpoint-down(lg) {
      background-position: center top;
    }
    @media (max-width: 815px) {
      //background-position: center center;
      //background-size: 65% auto;
      background-size: auto 50%;
    }
    @media (max-width: 615px) {
      //background-position: center center;
      background-size: auto 50%;
    }
  }

  p {
    max-width: 65%;
  }

  @include media-breakpoint-down(xl) {
    background-size: contain;
    height: 490px;
  }

  @include media-breakpoint-down(lg) {
    height: 390px;
    padding-bottom: 0;

    p {
      margin-bottom: 50px !important;
      max-width: 100%;
      //background-color: fade-out($section-backgrounds, 0.5);
      text-shadow: 1px 1px 2px rgba(0,0,0,0.9);
      padding: 5px;
      border-radius: 10px;
    }
  }

  h2 {
    color: $section-backgrounds;
  }
}
