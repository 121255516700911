div, a, p, h1, h2, h3, h4, h5, h6 {
  color: $white;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1, h2, h3 ,h4, h5, h6 {
  text-transform: uppercase;
}

h2 {
  font-size: 3rem;
  margin-bottom: 2.5rem;
}

h3 {
  margin-bottom: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.text-shadow {
  text-shadow: 0 8px 3px rgba(0,0,0,.2);
}

.red {
  color: #ff3000 !important;
}
.green {
  color: #7bff1a !important;
}
