section#stores {
  background-color: $section-backgrounds;
  padding: 38px 0;

  h2.header {
    margin-bottom: 28px;
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }

    span {
      color: $text-highlight
    }
    sup {
      text-transform: lowercase !important;
    }
  }

  .img-btn {
    height: 85px;
    width: auto;
    margin: 0 8px;

    @include media-breakpoint-down(sm) {
      width: 50% !important;
      height: auto !important;
      margin: 0 0 10px 0;
    }
  }
}
