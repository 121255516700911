@import "toronto";
@import "lightbox";
@import "bootstrapOverides";
@import "swiperCustom";
//Import all Bootstrap css
//@import 'bootstrap/scss/bootstrap.scss';

// or
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
//
// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/close";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/helpers";



// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

 // Example for setting variables
 $footer-text-color: #b1c4d0;
 $section-backgrounds: #000941;
 $page-and-footer-backgrounds: #151b26;
 $text: #fff;
 $text-highlight: #00e4ff;

//// 8. Add additional custom code here
@import "text";
@import "utilities";
@import "navbar";
@import "landing";
@import "career";
@import "arcade";
@import "equipment";
@import "thisIsHowWeRoll";
@import "screenshots";
@import "trailer";
@import "features";
@import "stores";
@import "lowerBanner";
@import "socialBanner";
@import "footer";




// Example for using variables
a {
  color: $text-highlight;
}

// Example for handling image loading.
//.demo-image {
//  background-image: url('Images/image.webp?size=#{$width}');
//}
