nav#navbar {
  background: rgba(0,0,0,0.5);
  text-transform: uppercase;
  //height: 76px;
  padding: 16px 0;
  position: fixed;
  left:0;
  top: 0;
  width: 100%;
  z-index: 100;

  @include media-breakpoint-down(md) {
    position: absolute;
  }

  #navLogo {
    position: absolute;
    height: 64px;
    margin-top: -5px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
      height: 45px;
      margin-top: 8px;

    }
  }

  .navbar-toggler {
    border: 0;

    span.navbar-toggler-icon {
      border: 0;
    }
  }

  .navbar-nav {
    margin-top: -1px;
    @include media-breakpoint-down(md) {
      //background: rgba(0, 0, 0, 0.5);
      //padding-top: 40px;
      //padding-bottom: 40px;
      text-align: center;
      margin-top: 14px;

    }
    .nav-link {
      color: $text-highlight;
      padding: 0 20px;
      font-size: 21px;

      &:hover {
        color: darken($text-highlight, 10%);
      }
    }
  }
}
