section#screenshots {
  div#screenshotsHeader {
    padding: 100px 0 60px;
    background-color: $section-backgrounds;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    overflow: hidden;
  }
  div#screenshotsBody {
    padding: 40px 0 90px;

    #screenshotsSlider {
      a.swiper-slide {
        display: flex;
        justify-content: center;
      }
      img {
        border: 5px solid $white;
        height: 600px;
        width: auto;
        max-width: 90vw;
        margin: 0 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $text-highlight !important;
  }
}
