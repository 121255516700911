footer#footer {
  padding: 70px 0;
  text-align: center;
  background-color: $page-and-footer-backgrounds;
  p {
    font-size: 1.2rem !important;
  }

  #footerLogo {
    width: 318px;
    height: auto;
    margin: 0 auto 10px;
    display: block;
  }

  span {
    color: $text-highlight;
  }
}
