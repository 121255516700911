section#trailer {
  background-color: $section-backgrounds;
  padding: 38px 0 58px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;

  #trailerWrapper {
    overflow: hidden;
    position:relative;
    height: 660px;
    width: 100%;
    max-width: 100%;

    //@include media-breakpoint-down(sm) {
    //  max-height: 75vh;
    //}

    iframe {
      border: 5px solid $white;
      //height: 540px;
      //width: 960px;
      //max-width: 100%;
      background-color: $page-and-footer-backgrounds;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      max-width: 100%;
      height: 100%;
      margin: 0 auto;

      //@include media-breakpoint-down(sm) {
      //  max-height: 75vh;
      //  width: auto;
      //}
    }
  }
}
