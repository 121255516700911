body {
  background-color: $section-backgrounds;
  color: $white;
  margin: 0;
  font-family: toronto_subwayregular, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-down(sm) {
    text-align: center;
    font-size: 16px !important;
  }
}

section {
  background-color: $section-backgrounds;
}

.header {
  width: 100%;
  text-align: center;

  .header-flash {
    position: relative;
    background-image: url("Images/sections/sectionFlash@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 600px;
    max-width: 100vw;
    background-position: center bottom;
    margin: 0 auto 0;
    display: block;
    height: 35px;
  }
}


.img-border {
  border: 4px solid $white;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    img.img-btn {
      width: 200px !important;
      margin: 5px 0 !important;
      height: auto !important;
    }
  }
}

@keyframes kenburns {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1.0);
    -ms-transform: scale(1.0);
    /* IE 9 */

    -webkit-transform: scale(1.0);
    /* Safari and Chrome */

    -o-transform: scale(1.0);
    /* Opera */

    -moz-transform: scale(1.0);
    /* Firefox */
  }
  100% {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    /* IE 9 */

    -webkit-transform: scale(1.2);
    /* Safari and Chrome */

    -o-transform: scale(1.2);
    /* Opera */

    -moz-transform: scale(1.2);
    /* Firefox */
  }
}
