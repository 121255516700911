section#landing {
  height: 76vh;
  padding: 3vh 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: start;
  position: relative;
  background-image: url('Images/bgs/posterView@2x.jpg');
  background-position: center center;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    padding: 10vh 0;
  }

  #bowlingFuryLogo {
    height: 650px;
    width: auto;

    @include media-breakpoint-down(sm) {
      width: 95vw;
      height: auto;
    }
  }

  &:before {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100vh;
    left: 0;
    content: "";
    background-image: url('Images/sections/firstSectionArcAndFlash@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
}

.store-links {
  width: 100%;
  background-color: $section-backgrounds;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0 0 40px;

  @include media-breakpoint-down(sm) {
    padding: 40px 0 40px 0;
  }

  .btn-group {
    margin-bottom: 8px;

    .googlePlay {
      width: 230px;
      height: 68px;
    }

    .googlePlay {
      width: 230px;
    }

    .img-btn {
        width: 230px;
        height: 68px;
        margin: 0 12px 10px;

        @include media-breakpoint-down(sm) {
          width: 50% !important;
          height: auto !important;
          margin: 0 0 10px 0;
        }
      }
    }

  h3 {
    margin-bottom: 0;
    span {
      color: $text-highlight;
    }
  }
}
