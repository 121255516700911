section#career {
  background-image: url('Images/bgs/career@2x.jpg');
  background-color: $section-backgrounds;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-top: 100px;
  padding-top: 300px;
  padding-bottom: 100px;

  @include media-breakpoint-down(lg) {
    padding-top: 150px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 100px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 50px;
  }

  &:before {
    background-image: url('Images/sections/careerLeagues@2x.png');
    background-repeat: repeat-x;
    background-position: top center;
    background-size: contain;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    height: 360px;
    width: auto;
    content: "";
  }

  #bf-logo-neon {
    max-width: 90%;
    height: 300px;
    transition: 0.45s;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }

    &.on {
      background-image: url('Images/logos/bfWithAlphaGlowOn@2x.png');
    }

    &.off {
      background-image: url('Images/logos/bfWithAlphaGlowOff@2x.png');
    }
  }

  .w-75 {
    margin-right: 0;
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      text-align: center;
      margin-right: auto;
      width: 100% !important;
    }
  }
}

