section#arcade {
  //width: 100%;
  //height: 380px;
  background-image: url('Images/bgs/arcade@2x.jpg');
  background-color: $section-backgrounds;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 200px;

  img.the-arcade {
    max-width: 90%;
    height: auto;
  }

  //&:before {
  //  background-image: url('Images/bgs/equipmentCurve@2x.png');
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //  background-position: bottom center;
  //  right: 0;
  //  position: absolute;
  //  bottom: 0;
  //  top: auto;
  //  width: 100%;
  //  height: 300px;
  //  left: 0;
  //  content: "";
  //
  //  @include media-breakpoint-down(xl) {
  //    background-size: contain;
  //  }
  //}
}
