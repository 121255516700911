section#features {
  position: relative;
  padding: 100px 0;
  background-image: url('Images/bgs/classic@2x.jpg');
  background-position: center center;
  background-size: cover;
  //background-attachment: fixed;
  z-index: 0;

  .feature {
    text-align: center;
    z-index: 1;

    img {
      width: 150px;
      height: auto;
    }

    h2 {
      color: $text-highlight;
      margin: 30px 0 10px 0;
    }
  }
}
