section#socialBanner {
  background-color: $section-backgrounds;
  padding: 30px 0;
  text-align: center;

  .img-btn {
    height: 76px;
    width: 76px;
    margin: 0 10px;
  }
}
