/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2016 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Quadrat Communications
 * License URL: https://www.fontspring.com/licenses/quadrat/webfont
 *
 *
 */

@font-face {
    font-family: 'toronto_subwayregular';
    src: url('Fonts/toronto_subway_regular-webfont.eot');
    src: url('Fonts/toronto_subway_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('Fonts/toronto_subway_regular-webfont.woff2') format('woff2'),
         url('Fonts/toronto_subway_regular-webfont.woff') format('woff'),
         url('Fonts/toronto_subway_regular-webfont.ttf') format('truetype'),
         url('Fonts/toronto_subway_regular-webfont.svg#toronto_subwayregular') format('svg');
    font-weight: normal;
    font-style: normal;

}



/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2016 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Quadrat Communications
 * License URL: https://www.fontspring.com/licenses/quadrat/webfont
 *
 *
 */

@font-face {
    font-family: 'toronto_subwaybold';
    src: url('Fonts/toronto_subway_bold-webfont.eot');
    src: url('Fonts/toronto_subway_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('Fonts/toronto_subway_bold-webfont.woff2') format('woff2'),
         url('Fonts/toronto_subway_bold-webfont.woff') format('woff'),
         url('Fonts/toronto_subway_bold-webfont.ttf') format('truetype'),
         url('Fonts/toronto_subway_bold-webfont.svg#toronto_subwaybold') format('svg');
    font-weight: normal;
    font-style: normal;

}

