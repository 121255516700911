section#thisIsHowWeRoll {
  background-image: url('Images/bgs/howWeRoll@2x.jpg');
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 90px 0;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }

  img {
    //margin: 20px 0 0 0;
    width: 40%;
    box-shadow: 0 10px 0 rgba(0,0,0,0.6);

    &.one {
      position: relative;
      z-index: 10;
      transform: rotate(-2deg);
      margin-top: -10px
    }

    &.two {
      transform: rotate(4deg);
    }
  }
}
